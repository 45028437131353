<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Tabla Superior -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!-- Boton Agregar Nueva Fiscalia -->
            <b-button
              variant="primary"
              class="mr-1"
              :to="{ name: 'slider-nuevo' }"
            >
              <feather-icon icon="PlusIcon" class="mr-50" />

              <span class="text-middle">Nuevo</span>
            </b-button>
            <!-- fin Boton Agregar -->
            <!-- Boton Actualizar Fiscalias @click="actualizarLista()" -->
            <b-button
              variant="secondary"
              class="btn-icon mr-2"
              @click="getAllSliders"
            >
              <feather-icon icon="RotateCcwIcon" />
            </b-button>
            <!-- fin Boton Actualizar -->
          </b-col>

          <!-- Buscar -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              />
            </div>
          </b-col>
          <!-- fin Buscar -->
        </b-row>
      </div>
      <b-col cols="12" class="my-2">
        <b-overlay
          :show="loading"
        >
      <b-table
        ref="refListTable"
        selectable
        select-mode="single"
        hover
        :small="true"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        @row-selected="onRowSelected"
        show-empty
        empty-text="No se encontraron registros coincidentes"
        :filter="searchQuery"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(selected)="{ rowSelected }">
          <template v-if="rowSelected">
            <i class="feather icon-disc primary" />
          </template>

          <template v-else>
            <i class="feather icon-circle" />
          </template>
        </template>
        
        <template #cell(estado)="data">
          <b-badge :variant="estado[1][data.value]">
            {{ estado[0][data.value] }}
          </b-badge>
        </template>
        <template #cell(activo)="data">
          <b-badge :variant="isactivo[1][data.value]">
            {{ isactivo[0][data.value] }}
          </b-badge>
        </template>


        <!-- Column: Actions -->

        <template #cell(accion)="data">
          <div class="text-nowrap">
            <!-- <b-button
                    :to="{ name: 'fiscalia-ver', params: { id: data.item.id } }"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-secondary"
                    v-b-tooltip.hover.bottom="'Ver'"
                    class="btn-icon mr-1"
                    size="sm"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button> -->
            <b-button
              :to="{ name: 'slider-editar', params: { id: data.item.id } }"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              v-b-tooltip.hover.left="'Editar'"
              class="btn-icon mr-1"
              size="sm"
            >
              <feather-icon icon="EditIcon" />
            </b-button>

            <b-button
              @click="confirmarEliminar(data.item)"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              v-b-tooltip.hover.left="'Eliminar'"
              class="btn-icon"
              size="sm"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </div>
        </template>
        <template #cell()="data">
          <span class="text-nowrap">{{ data.value }}</span>
        </template>

      </b-table>
              </b-overlay>  
      </b-col>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              size="sm"
              class="my-0"
            />
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import axios from "@axios";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BOverlay,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BOverlay,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
       
      searchQuery: null,
      selected: [],
      perPage: 10,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      loading: false,
      sortDirection: "asc",
      isactivo:[
          {
            1: 'Activado', 0: 'No activado'
          },
          {
            1: 'light-primary', 0: 'light-danger',
          }

      ],
        estado: [{
            1: 'Publicado', 2: 'Borrador', 3: 'Papelera',
          },
          {
            1: 'light-primary', 2: 'light-warning', 3: 'light-danger',
          }],

      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      tableColumns: [

        { key: "titulo", label: "Titulo", sortable: false },
        { key: "disenio", label: "Diseño", sortable: false },
        { key: "activo", label: "Activado", sortable: false },
        { key: "estado", label: "Estado", sortable: false },  
        "accion",
      ],
      items: [],
    };
  },

  created() {
    this.loading = true;
    this.getAllSliders();
  },

  methods: {
      

    confirmarEliminar(item) {
     

      this.$swal({
        title: "Esta seguro de eliminar ?",
        html: `<strong>${item.titulo}</strong>
                    <p>¡No podrás revertir esto!</p>
                    `,
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.eliminarItem(item.id).then((response) => {
            this.getAllSliders();
            if (response)
              this.$swal({
                icon: "success",
                title: "Eliminado!",
                timer: 2000,
                text: "Tu archivo ha sido eliminado.",
                confirmButtonText: "Aceptar",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
          });
        }
      });
    },

    async eliminarItem(id) {
      let rpt = true;
      await axios.delete(`/sliders/${id}`).catch((error) => {
        console.error(error);
        rpt = false;
      });
      return rpt;
    },

    onRowSelected(items) {
      this.selected = items;
    },
    getAllSliders() {
      this.loading = true;
      axios
        .get(`/sliders`)
        .then((response) => {
          this.items = response.data;
          this.totalRows = this.items.length;
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>